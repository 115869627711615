<template>
  <!-- <div></div> -->
  <div>
    <processing-modal :active="processing" v-on:cancel="processing = false" />
    <Card>
      <section class="main-content">
        <FormulateForm
          ref="form"
          @submit="validateForm"
          #default="{ isLoading }"
          v-model="formData"
          name="manualEntryForm"
        >
          <div class="row">
            <div class="col half">
              <div class="input-wrapper">
                <FormulateInput
                  type="select"
                  label="Comp or Will Call?"
                  :options="{
                    ComplimentaryTickets: 'Complimentary Tickets',
                    WillCall: 'Will Call'
                  }"
                  v-model="formData.type"
                  @input="onClickCompType"
                  validation="required"
                />
              </div>
            </div>
            <div class="col half">
              <div class="input-wrapper">
                <FormulateInput
                  type="select"
                  v-model="formData.issuedTo"
                  label="Issuing To"
                  :options="{
                    individual: 'Individual',
                    group: 'Group/Company'
                  }"
                  validation="required"
                />
              </div>
            </div>
            <div v-if="formData.issuedTo === 'group'" class="col half">
              <FormulateInput
                label="Group / Company Name"
                name="groupName"
                type="text"
                placeholder="Group / Company Name"
                validation="required"
              />
            </div>
            <div class="row" v-else>
              <div class="col half">
                <FormulateInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  validation="required"
                />
              </div>
              <div class="col half">
                <FormulateInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  validation="required"
                />
              </div>
            </div>

            <div class="col half">
              <FormulateInput
                name="email"
                label="Email"
                type="email"
                placeholder="Enter your email address"
                validation="required|email"
              />
            </div>
            <div class="col half">
              <FormulateInput
                label="Phone"
                name="phoneNumber"
                type="tel"
                placeholder="Phone Number"
              />
            </div>

            <div class="col half">
              <FormulateInput
                label="Location"
                name="location"
                type="text"
                placeholder="Country of Residence"
              />
            </div>
            <div v-if="compType !== 'WillCall'" class="col half">
              <TicketTierSelector
                :tiers="oldEvent.tiers"
                v-model="tier"
                name="tier"
              />
            </div>
            <div v-if="compType === 'WillCall'" class="col half">
              <TicketTierSelector
                :tiers="oldEvent.tiers"
                v-model="tier"
                name="tier"
                :willCall="true"
              />
            </div>
            <div class="col half" ref="pickup" v-if="compType === 'WillCall'">
              <!-- <diyobo-input
                label="Pickup Location"
                type="text"
                placeholder="Pickup Location"
                v-model="pickupLocation"
                :val="pickupLocation"
                :error="errors[1].error"
                :required="compType == 'WillCall'"
              /> -->

              <FormulateInput
                label="Pickup Location"
                name="pickupLocation"
                type="text"
                placeholder="Pickup Location"
                validation="required"
              />
            </div>
            <div class="col half">
              <div class="number-wrapper" v-if="tier && !tier.invalid">
                <!-- <diyobo-input
                  class="num-tix"
                  label="Number of Tickets to Issue"
                  type="number"
                  placeholder="Please Select A Quantity"
                  v-model="count"
                  :val="count"
                  :readOnly="isBulkUpload"
                  :error="errors[7].error"
                  :required="true"
                /> -->
                <FormulateInput
                  label="Number of Tickets to Issue"
                  name="count"
                  type="number"
                  placeholder="Please Select A Quantity"
                  :min="1"
                  :max="maxQuantity"
                  validation="required"
                  @input="renderSeats"
                  onwheel="this.blur()"
                  :validation-messages="{
                    max:
                      'Number of Tickets must be less than or equal to max quantity.'
                  }"
                />
              </div>
            </div>
            <div class="col half" v-if="tier && !tier.invalid && maxQuantity">
              <div class="quantity-number">Max Quantity: {{ maxQuantity }}</div>
            </div>

            <div
              class="col full reserved"
              v-if="
                tier &&
                  formData.count &&
                  tier.ticketType == 'IN_PERSON_RESERVED' &&
                  isEventReserveSeats
              "
            >
              <h3>Reserved Seats</h3>
              <div id="chart" class="reserved-chart"></div>
            </div>

            <div class="col full" v-if="formData.count">
              <FormulateInput
                class="reason"
                name="reason"
                label="Reason for Comps/Will Call"
                type="textarea"
                placeholder="Why are they getting these?"
                validation="required"
              />
            </div>
          </div>
          <div class="row">
            <div class="col half redirect-button">
              <FormulateInput
                class="redirect-button"
                type="button"
                name="Back to My Events"
                v-on:click="goBack"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="submit"
                :disabled="isLoading"
                :name="isLoading ? 'Submitting...' : 'Submit'"
              />
            </div>
          </div>
        </FormulateForm>
      </section>
    </Card>
    <div class="divider"></div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DataTable from "@/components/DataTable.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import PDFViewer from "@/components/PDFViewer.vue";
import RadioGroup from "@/components/RadioGroup.vue";
import TableTote from "@/components/TableTote.vue";
import TicketTierSelector from "@/components/TicketTierSelector.vue";
import ModalContent from "@/helpers/modals";
import {
  getSaleDate,
  getVenueAccessDate,
  getVenueAccessTime,
  viewTicketStock
} from "@/helpers/tickets.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import dayjs from "dayjs";
import CSVImporter from "../../components/comps/CSVImporter.vue";
import EventManagementPage from "../../components/EventManagementPage";
import LogoUploader from "../../components/imageUploaders/TTButtonImageUploader.vue";

library.add(faFilePdf);
library.add(faTicketAlt);

export default {
  name: "manual-entry",
  components: {
    EventManagementPage,
    DiyoboInput,
    DiyoboButton,
    TableTote,
    FontAwesomeIcon,
    RadioGroup,
    LogoUploader,
    Breadcrumbs,
    PDFViewer,
    ProcessingModal,
    TicketTierSelector,
    CSVImporter,
    DataTable
  },
  head() {
    return {
      title: "Comps / Will Call"
    };
  },
  data() {
    return {
      selectedSeats: [],
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Comps / Will Call", null]
      ],
      pdfTypes: { Letter: "Digital Format" },
      // Stock: "Stock"
      // Letter (8.5"x11")
      formData: {
        pdf: "Letter",
        type: "ComplimentaryTickets",
        issuedTo: "individual",
        firstName: "",
        lastName: "",
        groupName: "",
        email: "",
        phoneNumber: "",
        tier: null,
        pickupLocation: "",
        reason: "",
        language: "",
        location: ""
      },
      compsUploaded: [],
      bulkCompsFinal: [],
      fee: 0,
      tixLogo: "",
      ticketQuantity: [],
      ticketLogo: "",
      ticketLogoName: "",
      pdf: "Letter",
      tier: null,
      count: "",
      dateInputID: "",
      saleDateRefArray: [],
      saleDateArr: [],
      compType: "ComplimentaryTickets",
      pickupLocation: "",
      oldEvent: {},
      compButtonTypes: [
        {
          id: "check-comp",
          name: "comp-types",
          label: "Complimentary Tickets",
          checked: true
        },
        { id: "check-will-call", name: "comp-types", label: "Will Call" }
      ],

      imgType: "New Upload",
      compsTableData: {
        title: "Complimentary Tickets",
        columns: [
          { text: "Full Name", value: "name" },
          { text: "Email", value: "email" },
          {
            text: "Ticket Tier",
            value: "tier"
          },
          // {
          //   title: "Tier Date",
          //   field: "ticket_date",
          //   width: wcHasNoTierTimes ? "16%" : "8%",
          //   hidden: wcHasNoTierDates
          // },
          // {
          //   title: "Tier Time",
          //   field: "ticket_time",
          //   width: "8%",
          //   hidden: wcHasNoTierTimes
          // },
          // {
          //   text: "Language",
          //   value: "language"
          // },
          // {
          //   text: "Location",
          //   value: "location"
          // },
          {
            text: "Created",
            value: "created"
          },
          { text: "# of Tix", value: "ticket_count" },
          // { text: "Reason", value: "reason" },
          { text: "Active", value: "active" },
          { text: "Tickets", value: "print" }
        ]
      },
      willcallTableData: {
        title: "Will Call Tickets",
        // subtitle:
        //   "Please use 'Receipt' to download will call for customer and 'Print' when they are collecting tickets at the venue.",
        columns: [
          { text: "Full Name", value: "name" },
          { text: "Email", value: "email" },
          // { text: "Phone Number", value: "phone" },
          {
            text: "Ticket Tier",
            value: "tier"
          },
          // {
          //   title: "Tier Date",
          //   field: "ticket_date",
          //   width: wcHasNoTierTimes ? "16%" : "8%",
          //   hidden: wcHasNoTierDates
          // },
          // {
          //   title: "Tier Time",
          //   field: "ticket_time",
          //   width: "8%",
          //   hidden: wcHasNoTierTimes
          // },
          {
            text: "Created",
            value: "created"
          },
          { text: "# of Tix", value: "ticket_count" },
          { text: "Slip", value: "slip" },
          { text: "Tickets", value: "print" }

          // { text: "Reason", value: "reason" }
          // {
          //   title: "Type",
          //   field: "print_type",
          //   hidden: true
          // },
          // {
          //   title: "Active",
          //   field: "active",
          //   width: "5%",
          //   yesNo: true
          // },
          // {
          //   title: "Slip",
          //   field: "name",
          //   width: "5%",
          //   icon: true
          // },
          // {
          //   title: "Tickets",
          //   field: "print_type",
          //   width: "5%",
          //   icon: true
          // }
        ]
      },
      maxQuantity: null,
      pdfData: null,
      pdfFileName: "",
      isWillCallSlip: false,
      processing: false,
      eventDateID: null,
      whiteLabelId: "",
      issuedTo: "individual",
      event: [],
      isEventReserveSeats: false,
      chart: null,
      seats: []
    };
  },

  watch: {
    tier: {
      handler(values) {
        if (!values) {
          return;
        }
        this.changeQuantity();
        this.count = "";
        this.reason = "";
        this.pdf = "Letter";
        this.imgType = "New Upload";
      },
      deep: true
    }
  },
  computed: {
    eventUrl() {
      return this.$route.params.url;
    }
  },
  methods: {
    getTicket() {
      let availableTiers = [];
      let ticket = [];
      for (const tier of this.oldEvent.tiers) {
        const saleDates = tier.saleDates.filter(
          d =>
            dayjs(+d.sale_start.$date.$numberLong).isBefore(dayjs()) &&
            dayjs(+d.sale_end.$date.$numberLong).isAfter(dayjs())
        );

        const venueAccessDates = (tier.venueAccessDates || [])
          .filter(d => d.enabled)
          .map(d => ({
            id: d.id,
            start: dayjs(+d.start),
            end: dayjs(+d.end),
            qty: d.available,
            event_date_id: d.event_date_id,
            type: d.type,
            times: d.times
              ? d.times
                  .filter(t => t.enabled)
                  .map(t => ({
                    id: t.id,
                    start: dayjs(+t.start),
                    end: dayjs(+t.end),
                    qty: t.available
                  }))
              : null
          }));

        for (const saleDate of saleDates) {
          availableTiers.push({
            ...tier,
            saleDateId: saleDate.id,
            qty: saleDate.qty,
            venueAccessQty: venueAccessDates.reduce((a, b) => a + b.qty, 0),
            start: saleDate.start,
            soldout: saleDate.soldout || tier.soldout,
            venueAccessDates,
            passwordValue: ""
          });
        }
      }
      for (const t of availableTiers) {
        if (t.id == this.formData.tier) {
          ticket = t;
        }
      }
      return ticket;
    },
    selectSeats(seats) {
      console.log(seats);
      this.seats.push(seats.id);
    },
    deselectSeats(seats) {
      let index = this.seats.indexOf(seats.id);
      this.seats = this.seats.splice(index, 1);
    },
    renderSeats() {
      if (!this.isEventReserveSeats) {
        return;
      }
      if (this.count !== parseInt(this.formData.count)) {
        if (this.chart) {
          try {
            this.chart.destroy();
          } catch ($e) {
            console.log("can't destroy chart");
          }
        }
        this.count = parseInt(this.formData.count);
        if (!this.count || this.count <= 0) {
          return;
        }
        let ticket = this.getTicket();
        let self = this;
        this.chart = new seatsio.SeatingChart({
          divId: "chart",
          event: this.event.seats_event_key,
          workspaceKey: this.event.workspace_key,
          pricing: [
            {
              category: ticket.categoryKey,
              price: ticket.price,
              label: ticket.name,
              description: ticket.description
            }
          ],
          availableCategories: [ticket.categoryKey],
          maxSelectedObjects: parseInt(this.formData.count),
          onObjectSelected: function(object) {
            self.selectSeats(object);
          },
          onObjectDeselected: function(object) {
            self.deselectSeats(object);
          },
          features: {
            readOnly: ["chartName", "categoryList"]
          },
          priceFormatter: function(price) {
            return "$" + price;
          },
          priceLevelsTooltipMessage: "Select a ticket type"
        });
        this.chart.render();
      }
    },

    issuingTo(value) {
      this.issuedTo = value;
    },
    print(item) {
      this.viewTickets(item);
    },

    changeQuantity() {
      this.ticketQuantity = [];
      let self = this;
      const tiers = this.oldEvent.tiers;

      if (!this.tier) {
        self.maxQuantity = 0;
      }
      if (this.tier.type === "General Tier") {
        const saleDate = getSaleDate(tiers, this.tier.saleDateID);
        if (saleDate) {
          let tierQuantity = saleDate.qty;
          for (
            let y = tierQuantity - saleDate.start + 1;
            y < tierQuantity - saleDate.start + 2;
            y++
          ) {
            self.maxQuantity = y;
          }
        }
      } else if (this.tier.type === "Date") {
        const venueAccessDate = getVenueAccessDate(
          tiers,
          this.tier.venueAccessDateID
        );
        if (venueAccessDate) {
          self.maxQuantity = venueAccessDate.available;
        }
      } else if (this.tier.type === "Date and Time") {
        const venueAccessTime = getVenueAccessTime(
          tiers,
          this.tier.venueAccessDateID,
          this.tier.venueAccessTimeID
        );

        if (venueAccessTime) {
          self.maxQuantity = venueAccessTime.available;
        }
      }

      this.count = "";
      this.reason = "";
      this.pdf = "Letter";
      this.imgType = "New Upload";
    },
    validateForm() {
      this.$loader.start();
      try {
        this.createCompWillCall();
      } catch (error) {
        this.$loader.stop();
      }
    },
    async createCompWillCall() {
      // let self = this;
      let arrayOfNumbers = [];
      let date = Date.now();
      let tiers = this.oldEvent.tiers;
      let totalTickets = this.formData.count;
      const elem = tiers.find(t => t.id === this.tier.tierID);

      let startNumber;
      let endNumber;
      let tierDate;
      let saleDate;
      let tierType;
      if (elem.type === "General Tier") {
        saleDate = getSaleDate(elem, this.tier.saleDateID);
        startNumber = saleDate.start;
        endNumber = parseInt(startNumber) + parseInt(totalTickets);
        saleDate.start += parseInt(totalTickets);
        tierType = saleDate.type;
        tierDate = dayjs(+saleDate.sale_start.$date.$numberLong).toISOString();
      } else if (elem.type === "Date") {
        const venueAccessDate = getVenueAccessDate(
          elem,
          this.tier.venueAccessDateID
        );
        startNumber = venueAccessDate.sold + elem.tixStartNum;
        endNumber = parseInt(startNumber) + parseInt(totalTickets);
        venueAccessDate.sold += parseInt(totalTickets);
        venueAccessDate.available -= parseInt(totalTickets);
        console.log(venueAccessDate);
        tierType = venueAccessDate.type;
        tierDate = dayjs(
          +venueAccessDate.start.$date.$numberLong
        ).toISOString();
      } else if (elem.type === "Date and Time") {
        const venueAccessTime = getVenueAccessTime(
          elem,
          this.tier.venueAccessDateID,
          this.tier.venueAccessTimeID
        );
        startNumber = venueAccessTime.sold + elem.tixStartNum;
        endNumber = parseInt(startNumber) + parseInt(totalTickets);
        venueAccessTime.sold += parseInt(totalTickets);
        venueAccessTime.available -= parseInt(totalTickets);
        tierType = venueAccessTime.type;
        tierDate = dayjs(
          +venueAccessTime.start.$date.$numberLong
        ).toISOString();
      }

      for (let t = startNumber; t < endNumber; t++) {
        arrayOfNumbers.push(t);
      }

      let NumOfTickets = totalTickets;
      let type = this.pdf.trim();
      let compType = this.compType;
      let img = this.tixLogo;
      let event_id = this.oldEvent._id;
      if (type.includes("Letter")) {
        type = "Letter";
      }

      let ticketName = "";

      if (this.formData.issuedTo === "group") {
        ticketName = this.formData.groupName;
        this.formData.firstName = ticketName;
        this.formData.lastName = " ";
      } else {
        ticketName = this.formData.firstName + " " + this.formData.lastName;
      }

      const ticket = {
        ...this.formData,
        fullName: ticketName,
        event_id: event_id,
        tier_id: elem.id,
        ticketTier: elem.name,
        ticketsCount: NumOfTickets,
        creationDate: date,
        type: "Complimentary Tickets",
        compType: compType,
        eventUrl: this.eventUrl,
        tickets: arrayOfNumbers,
        icon: img,
        iconName: this.ticketLogoName,
        ticketType: tierType,
        saleDateID: saleDate ? saleDate.id : null,
        venueAccessDateID: this.tier.venueAccessDateID,
        venueAccessTimeID: this.tier.venueAccessTimeID,
        price: 0,
        fee: 0,
        date: this.eventDateID,
        tierDate,
        seats: this.seats || []
      };

      // send as FormData to properly handle image uploads
      const data = new FormData();
      data.append("data", JSON.stringify(ticket));
      if (this.imgType === "New Upload") {
        data.append("icon", this.ticketLogo);
      }

      let user = this.$store.state.user._id;

      try {
        let whiteLabelAcc = await this.$axios.post(
          "/white-label/get-white-label-by-user-id",
          { userId: user }
        );

        if (whiteLabelAcc.data.hasDomain) {
          this.whiteLabelId = await whiteLabelAcc.data.whiteLabel._id;
        }
      } catch (error) {
        console.log(error);
      }

      this.$axios
        .post("/comps/comp-tickets", data, {
          headers: {
            "X-Whitelabel-Id": this.whiteLabelId
          }
        })
        .then(response => {
          let obj = response.data;
          obj.created = dateFormat(
            parseInt(obj.created.$date.$numberLong),
            "ddd, mmm dS, yyyy"
          );
          obj.print = obj.print_type;

          obj.ticket_date = "";
          obj.ticket_time = "";

          if (obj.tierDate && obj.tierDate.$date) {
            if (obj.tierType === "Date" || obj.tierType === "Date and Time") {
              obj.ticket_date = dayjs(+obj.tierDate.$date.$numberLong).format(
                "MMM DD, YYYY"
              );
            }

            if (obj.tierType === "Date and Time") {
              obj.ticket_time = dayjs(+obj.tierDate.$date.$numberLong).format(
                "h:mm A"
              );
            }
          }

          if (obj.type === "WillCall") {
            this.$emit("addWillCall", obj);
          } else {
            this.$emit("addComp", obj);
          }

          this.imgType = "New Upload";
          this.clearTable();
          this.$store.state.bus.$emit("alert", ModalContent.createCompWillCall);
          // if (obj.type == "WillCall") {
          //   this.$store.state.bus.$emit("alert", ModalContent.willcall);
          // } else {
          //   this.$store.state.bus.$emit("alert", ModalContent.comp);
          // }
          this.$loader.stop();
        });
    },

    injectTicketLogo(key, value) {
      this[key] = value.imageFile;
      this.ticketLogoName = value.imageName;
    },
    clearTable() {
      let type = this.formData.type;
      let issuedTo = this.formData.issuedTo;
      this.$formulate.reset("manualEntryForm");
      this.$formulate.setValues("manualEntryForm", {
        pdf: "Letter",
        type: type,
        issuedTo: issuedTo,
        firstName: "",
        lastName: "",
        groupName: "",
        email: "",
        phoneNumber: "",
        tier: null,
        pickupLocation: "",
        reason: "",
        language: "",
        location: ""
      });
      this.$bus.$emit("reset-phone-field");
      this.tier = null;
      this.dateInputID = "";
      this.tixLogo = "";
      this.compType = type;
    },
    goBack() {
      this.$router.push("/my-events");
    },
    onClickCompType(value) {
      this.compType = value;
      // this.$refs.pickup.classList.toggle("hidden", true);
    },
    onClickPdfType(value) {
      this.pdf = value;
    },
    clickImgTyp(value) {
      this.imgType = value.trim();
    },
    viewTicketStock(value, oldEvent) {
      let canvas = document.getElementById("canvas");
      let canvas1 = document.getElementById("icon-canvas");
      this.processing = true;
      viewTicketStock(value, oldEvent, canvas, canvas1, tickets => {
        this.pdfData = tickets.pdf;
        this.pdfFileName = tickets.fileName;
        this.processing = false;
      });
    },
    viewTickets(ticket) {
      let self = ticket;
      let comps = true;

      this.$axios
        .post(
          "/tickets/get-order-tickets-pdf",
          {
            //FIXME:: old api
            // order: this._id.$oid,
            order: self._id,
            timezone: self.event_data[0].timezone,
            comps: comps
          },
          {
            headers: {
              Accept: "application/pdf"
            },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          setTimeout(() => {
            const blobPDF = new Blob([data], {
              type: "application/pdf"
            });
            const blobUrl = URL.createObjectURL(blobPDF);
            this.pdfData = blobUrl;
            this.pdfFileName = "comps-tickets.pdf";
          }, 500);
          // self.closeModal(2);
        });
    },
    onLoad() {
      let self = this;
      // for multi date events
      // this.eventDateID = dateID;

      this.$axios.get(`/comps/get-comps/${this.eventUrl}`).then(({ data }) => {
        this.oldEvent = data.data.event;

        this.fee = parseFloat(data.data.fee.$numberDecimal);

        // this.clearTable();
      });
    }
  },

  async mounted() {
    await this.$axios
      .get(`/events/edit/${this.$route.params.url}`)
      .then(response => {
        this.event = response.data;
      });
    if (this.event && this.event.eventType == "RESERVED_SEATS") {
      this.isEventReserveSeats = true;
    }
    if (this.isEventReserveSeats) {
      let script = document.createElement("script");
      script.setAttribute("src", "https://cdn-na.seatsio.net/chart.js");
      document.head.appendChild(script);
    }
    //FIXME:: old api
    // let user = this.$store.state.user._id.$oid;
    let user = this.$store.state.user._id;

    try {
      let whiteLabelAcc = await this.$axios.post(
        "/white-label/get-white-label-by-user-id",
        { userId: user }
      );

      if (whiteLabelAcc.data.hasDomain) {
        this.whiteLabelId = await whiteLabelAcc.data.whiteLabel._id;
      }
    } catch (error) {
      console.log(error);
    }

    // Used to be on onload of header
    this.onLoad();
  }
};
</script>
<style>
.formulate-input-element.formulate-input-element--group.formulate-input-group {
  display: flex;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>

<style lang="less">
.redirect-button {
  text-align: -webkit-right;

  .formulate-input[data-classification="button"] button {
    background: var(--secondary-green);
  }
}

.formulate-input {
  &[data-classification="submit"] button {
    margin-left: initial;
  }
}

.quantity-number {
  display: flex;
  padding-top: 10px;
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
}
</style>

<style lang="less" scoped>
.splitter {
  display: flex;
  justify-content: center !important;
  position: relative;
  font-weight: bold;
  padding: 10px;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    right: 58%;
    width: 25%;
    border-bottom: 2px solid @colors[primary-green];
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 58%;
    width: 25%;
    border-bottom: 2px solid @colors[primary-green];
  }
}

::-webkit-scrollbar {
  display: none;
}

.search-input {
  width: 200px;
}

.img-cont {
  display: none;
}

#Content {
  .content-inner {
    position: relative;

    .number-wrapper {
      display: flex;

      .num-tix {
        width: 75%;
      }
    }

    .row {
      .input-wrapper {
        margin-bottom: 10px;
      }

      .logo-uploader {
        margin-top: 25px;
      }
    }

    .divider {
      margin: 1.5em 0px;
    }

    .main-content {
      margin-bottom: 1.1em;

      .logo-uploader {
        margin: 0px !important;
      }
    }

    p {
      color: #868686;

      a {
        color: #868686;
      }
    }

    .errors {
      color: #ffffff;
    }

    .comps-buttons-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      #submit-button {
        margin-left: 16px;
        width: 132px;
      }
    }

    // end of comps-buttons-wrapper
    .hidden {
      visibility: hidden;
      display: none;
    }
  }

  .reserved-chart {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--event-border);
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 10px;
  }
}

// end of content-inner
</style>
